#mapid {
  height: 100%;
  width: 100%;
  left: 0;
}

.map {
  z-index: 10 !important;
}

/* Add these styles to your CSS file */
.swiper-pagination {
  bottom: -0 !important; /* Adjust this value to move dots up/down */
}

.swiper-pagination-bullet {
  background: white !important; /* Optional: makes the dots white */
  width: 10px !important;
  height: 10px !important;
}

.swiper {
  height: auto !important;
  padding-bottom: 50px !important;
  border: unset !important;
}

.swiper-scrollbar {
  display: none !important;
}

.footer-input {
  ::placeholder {
    color: red !important;
  }
}

a {
  text-decoration: none !important;
  .event-card {
    text-decoration: none !important;
    color: #000 !important;
  }
}


.grid-gallery {
  width: 100%;
  padding: 20px 0;
}

// .grid-gallery-inner {
//   display: grid;
//   grid-template-columns: repeat(5, 1fr);
//   grid-auto-rows: 200px;
//   gap: 15px;
// }

.grid-gallery-inner {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 240px;
  gap: 20px;
  padding: 20px;
}

.gallery-item {
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  aspect-ratio: 1;
}

@media (max-width: 768px) {
  .grid-gallery-inner {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 125px;
    gap: 5px;
    padding: 0px;
  }
}

@media (max-width: 480px) {
  .grid-gallery-inner {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 130px;
    gap: 35px;
  }
}

.ticketCard {
  .ribbon {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 100;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: left;
  }
  
  .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: red;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
  }
}

.event-details {
  color: inherit !important;
  background-color: transparent !important;
  font-weight: inherit !important;
  font-style: inherit !important;
}

.event-details {
  p, ul, li, span {
    color: white !important;
  }
}

.event-details {
  p {
    margin-bottom: 0px !important;
  }
  ol {
    margin-bottom: 10px !important;
  }
}

.event-details p {
  margin-bottom: 8px;
}

.event-details ul li {
  margin-bottom: 5px;
}

.ticketCard {
  margin-bottom: 20px;
  .card {
    background-color: #ffffff !important;
  }
  .ribbon {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 100;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: left;
  }
  
  .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: red;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
  }
}

.event-button {
  z-index: 2000 !important;
}

@media (max-width: 767px) {
  .event-button {
    position: fixed;
    bottom: -1px;
    left: 0;
    width: 100%;
    z-index: 2000 !important;
  }

  .hidden-mobile {
    display: none;
  }

  .event-pay-buttons {
    position: fixed;
    bottom: -1px;
    left: 0;
    width: 100%;
    z-index: 9999 !important;
    background-color: #ffffff !important;
    // top shadow
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    padding: 18px;
  }

  .modal.show .modal-dialog {
    transform: unset !important
}
}


.event-button2 {
  visibility: hidden;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.event-card2:hover .event-button2 {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}